@if (userData$ | async; as userData) {
  <div class="md:flex md:min-w-72 hidden flex-col">
    <div class="flex px-4 pb-2 pt-6">
      <div class="relative flex items-center">
        <img
          class="h-8 w-8 rounded-full bg-gray-50"
          src="https://media.istockphoto.com/id/518552551/ro/fotografie/imagine-de-profil-siluet%C4%83-masculin%C4%83-cu-semn-de-%C3%AEntrebare.jpg?s=2048x2048&w=is&k=20&c=At0q0trO71AbBOqd0ajhj3sZFX7mNkZHVC2hvydCiXc="
        />
        <span class="hidden lg:flex lg:items-center">
          <span class="ml-4 text-sm font-semibold leading-6 text-gray-900">{{ userData.email }}</span>
        </span>
      </div>
    </div>
    <nav class="flex px-2 mt-2 flex-1 flex-col">
      <ul class="flex justify-between flex-1 flex-col gap-y-7">
        <li>
          @for (menuItem of menu; track menuItem.label) {
            @if (!userData.roles.isOperator || menuItem.availableForOperator) {
              <a
                [routerLinkActive]="'bg-indigo-600 text-white '"
                [routerLink]="menuItem.routerLink"
                class="flex gap-x-1 rounded-md p-4 text-[14px] hover:bg-indigo-800 hover:text-white font-semibold leading-6"
              >
                {{ menuItem.label }}
              </a>
            }
          }
        </li>
      </ul>
    </nav>
  </div>
}
