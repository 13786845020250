<div class="w-full bg-indigo-400 flex items-center border-b border-gray-200 px-4 shadow-sm">
  <div class="flex md:hidden">
    <app-mobile-menu></app-mobile-menu>
  </div>
  <div class="flex flex-1">
    <span class="md:text-3xl uppercase text-indigo-800 font-extrabold text-xl py-6">Registru dashboard</span>
  </div>
  <div class="hidden md:flex md:absolute right-10 justify-end">
    <button
      (click)="logout()"
      class="relative text-white inline-flex items-center rounded-md bg-indigo-700 px-4 py-2 text-sm font-semibold shadow-sm hover:bg-indigo-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      Deconectare
    </button>
  </div>
</div>
