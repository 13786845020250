import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SidebarMenuService } from './sidebar-menu.service';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { AuthService } from '../../../auth/services/auth.service';
import { Memoized } from '@acetech-development/utilities/core';
import { SidebarMenuItemModel } from './sidebar-menu.model';
import { Observable } from 'rxjs';
import { UserModel } from '../../models/user.model';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    // 3rd
    AsyncPipe,
    RouterLink,
    RouterLinkActive,
    RouterOutlet,
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
  private readonly sidebarMenuService = inject(SidebarMenuService);
  private readonly authService = inject(AuthService);

  @Memoized
  public get userData$(): Observable<UserModel> {
    return this.authService.userData$;
  }

  @Memoized
  public get menu(): SidebarMenuItemModel[] {
    return this.sidebarMenuService.sidebarMenu;
  }
}
