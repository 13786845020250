import { ChangeDetectionStrategy, Component, inject, OnDestroy } from '@angular/core';
import { AsyncPipe, NgClass } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SidebarMenuService } from '../sidebar/sidebar-menu.service';
import { Memoized } from '@acetech-development/utilities/core';
import { SidebarMenuItemModel } from '../sidebar/sidebar-menu.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.css'],
  standalone: true,
  imports: [
    // 3rd
    AsyncPipe,
    NgClass,
    RouterLinkActive,
    RouterLink,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileMenuComponent implements OnDestroy {
  private readonly sidebarMenuService = inject(SidebarMenuService);
  private readonly authService = inject(AuthService);

  private readonly isMenuOpenSubject = new BehaviorSubject<boolean>(false);

  public ngOnDestroy(): void {
    this.isMenuOpenSubject.complete();
  }

  public toggleSidebar(): void {
    this.isMenuOpenSubject.next(!this.isMenuOpenSubject.value);
  }

  public logout(): void {
    this.authService.logout();
  }

  @Memoized
  public get menu(): SidebarMenuItemModel[] {
    return this.sidebarMenuService.sidebarMenu;
  }

  @Memoized
  public get isMenuOpen$(): Observable<boolean> {
    return this.isMenuOpenSubject.asObservable();
  }
}
