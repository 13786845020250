import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { HeaderComponent } from '../header/header.component';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { AuthService } from '../../../auth/services/auth.service';
import { Memoized } from '@acetech-development/utilities/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-main-container',
  standalone: true,
  imports: [
    // 3rd
    AsyncPipe,
    NgTemplateOutlet,
    RouterOutlet,
    // Project
    HeaderComponent,
    SidebarComponent,
  ],
  templateUrl: './main-container.component.html',
  styleUrl: './main-container.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainContainerComponent {
  private readonly authService = inject(AuthService);

  @Memoized
  public get isAuthenticated$(): Observable<boolean> {
    return this.authService.isAuthenticated$;
  }
}
