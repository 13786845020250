import { Injectable } from '@angular/core';
import { SidebarMenuItemModel } from './sidebar-menu.model';
import { NAVIGATION_PATH } from '../../constants/navigation-paths';

@Injectable({
  providedIn: 'root',
})
export class SidebarMenuService {
  public sidebarMenu: SidebarMenuItemModel[] = [
    {
      label: 'Agenții',
      routerLink: NAVIGATION_PATH.AGENCIES,
      availableForOperator: true,
    },
    {
      label: 'Utilizatori',
      routerLink: NAVIGATION_PATH.USERS,
      availableForOperator: false,
    },
    {
      label: 'Registru Pariuri',
      routerLink: NAVIGATION_PATH.BET_REGISTER,
      availableForOperator: true,
    },
    {
      label: 'Tranzacții',
      routerLink: NAVIGATION_PATH.TRANSACTIONS,
      availableForOperator: true,
    },
  ];
}
