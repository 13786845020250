<div class="md:hidden flex items-center justify-between p-4">
  <button (click)="toggleSidebar()" aria-label="Toggle sidebar">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
    </svg>
  </button>
</div>

<div
  [ngClass]="{ 'fixed inset-0 bg-gray-800 bg-opacity-75 z-50': (isMenuOpen$ | async), 'hidden': !(isMenuOpen$ | async) }"
  class="md:hidden flex flex-col"
>
  <nav class="flex flex-col h-full bg-white">
    <div class="flex justify-end p-4">
      <button (click)="toggleSidebar()" aria-label="Close sidebar">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
    <ul class="flex flex-col flex-1 gap-y-7 p-4">
      @for (menuItem of menu; track menuItem) {
        <li>
          <a
            (click)="toggleSidebar()"
            [routerLinkActive]="'bg-gray-400'"
            [routerLink]="menuItem.routerLink"
            class="flex gap-x-1 rounded-md p-4 text-[14px] hover:bg-gray-300 hover:text-indigo-600 font-semibold leading-6"
          >
            {{ menuItem.label }}
          </a>
        </li>
      }
      <button
        (click)="logout()"
        class="flex gap-x-1 mt-8 rounded-md p-4 text-[14px] hover:bg-gray-300 hover:text-indigo-600 font-semibold leading-6"
      >
        Deconectare
      </button>
    </ul>
  </nav>
</div>
