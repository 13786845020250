@if (isAuthenticated$ | async) {
  <ng-container *ngTemplateOutlet="loggedInPageLayout"></ng-container>
} @else {
  <ng-container *ngTemplateOutlet="loggedOutPageLayout"></ng-container>
}
<ng-template #loggedInPageLayout>
  <div class="overflow-x-auto">
    <app-header></app-header>
    <div class="flex gap-2 flex-row">
      <app-sidebar></app-sidebar>
      <div class="bg-gray-300 w-full">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #loggedOutPageLayout>
  <router-outlet></router-outlet>
</ng-template>
